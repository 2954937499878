.grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex: 0 1 auto;
  flex-wrap: wrap;
  padding-left: $base-padding/2;
  padding-right: $base-padding/2;

  @include sm {
    padding-left: $base-padding/1.5;
    padding-right: $base-padding/1.5;
  }
  @include md {
    padding-left: $base-padding;
    padding-right: $base-padding;
  }

  & & {
    padding-left: 0;
    padding-right: 0;
  }

  @at-root .no-flexbox {
    @include clearfix;
  }
}

[class*='col-'] {
  flex: 0 0 auto;

  @include xs {
    margin-right: 0;
  }

  @at-root .no-flexbox & {
    float: left;
  }
}

// padding
.padding {
  padding: $base-padding/1.5;

  @include sm {
    padding: $base-padding;
  }

  &--horizontal {
    padding-top: $base-padding/1.5;
    padding-bottom: $base-padding/1.5;

    @include sm {
      padding-top: $base-padding;
      padding-bottom: $base-padding;
    }
  }
}

// columns


.col-1-1 {
  flex-basis: percentage(1 / 1);
  width: percentage(1 / 1);
  max-width: percentage(1 / 1);
}
.col-2-3 {
  flex-basis: percentage(2 / 3);
  width: percentage(2 / 3);
  max-width: percentage(2 / 3);
}
.col-1-3 {
  flex-basis: percentage(1 / 3);
  width: percentage(1 / 3);
  max-width: percentage(1 / 3);
}
.col-1-2 {
  flex-basis: percentage(1 / 2);
  width: percentage(1 / 2);
  max-width: percentage(1 / 2);
}
.col-1-4 {
  flex-basis: percentage(1 / 4);
  width: percentage(1 / 4);
  max-width: percentage(1 / 4);
}
.col-1-8 {
  flex-basis: 12.5%;
  width: percentage(1 / 8);
  max-width: percentage(1 / 8);

  @include xs {
    flex-basis: percentage(1 / 8);
    width: percentage(1 / 8);
    max-width: percentage(1 / 8);
  }
}

@include xs {
  .col-xs-1-1 {
    flex-basis: percentage(1 / 1);
    width: percentage(1 / 1);
    max-width: percentage(1 / 1);
  }
  .col-xs-2-3 {
    flex-basis: percentage(2 / 3);
    width: percentage(2 / 3);
    max-width: percentage(2 / 3);
  }
  .col-xs-1-3 {
    flex-basis: 33.33%;
    width: percentage(1 / 3);
    max-width: percentage(1 / 3);
  }
  .col-xs-1-2 {
    flex-basis: 50%;
    width: percentage(1 / 2);
    max-width: percentage(1 / 2);
  }
  .col-xs-1-4 {
    flex-basis: 25%;
    width: percentage(1 / 4);
    max-width: percentage(1 / 4);
  }
  .col-xs-1-8 {
    flex-basis: 12.5%;
    width: percentage(1 / 8);
    max-width: percentage(1 / 8);
  }
}

@include sm {
  .col-sm-1-1 {
    flex-basis: percentage(1 / 1);
    width: percentage(1 / 1);
    max-width: percentage(1 / 1);
  }
  .col-sm-2-3 {
    flex-basis: percentage(2 / 3);
    width: percentage(2 / 3);
    max-width: percentage(2 / 3);
  }
  .col-sm-1-3 {
    flex-basis: percentage(1 / 3);
    width: percentage(1 / 3);
    max-width: percentage(1 / 3);
  }
  .col-sm-1-2 {
    flex-basis: percentage(1 / 2);
    width: percentage(1 / 2);
    max-width: percentage(1 / 2);
  }
  .col-sm-1-4 {
    flex-basis: 25%;
    width: percentage(1 / 4);
    max-width: percentage(1 / 4);
  }
  .col-sm-3-4 {
    flex-basis: 75%;
    width: percentage(3 / 4);
    max-width: percentage(3 / 4);
  }
  .col-sm-1-8 {
    flex-basis: 12.5%;
    width: percentage(1 / 8);
    max-width: percentage(1 / 8);
  }
}

@include md {
  .col-md-1-1 {
    flex-basis: percentage(1 / 1);
    width: percentage(1 / 1);
    max-width: percentage(1 / 1);
  }
  .col-md-2-3 {
    flex-basis: percentage(2 / 3);
    width: percentage(2 / 3);
    max-width: percentage(2 / 3);
  }
  .col-md-1-3 {
    flex-basis: percentage(1 / 3);
    width: percentage(1 / 3);
    max-width: percentage(1 / 3);
  }
  .col-md-1-2 {
    flex-basis: percentage(1 / 2);
    width: percentage(1 / 2);
    max-width: percentage(1 / 2);
  }
  .col-md-1-4 {
    flex-basis: percentage(1 / 4);
    width: percentage(1 / 4);
    max-width: percentage(1 / 4);
  }
  .col-md-3-4 {
    flex-basis: percentage(3 / 4);
    width: percentage(3 / 4);
    max-width: percentage(3 / 4);
  }
  .col-md-1-8 {
    flex-basis: percentage(1 / 8);
    width: percentage(1 / 8);
    max-width: percentage(1 / 8);
  }
}

@include lg {
  .col-lg-1-1 {
    flex-basis: percentage(1 / 1);
    width: percentage(1 / 1);
    max-width: percentage(1 / 1);
  }
  .col-lg-2-3 {
    flex-basis: percentage(2 / 3);
    width: percentage(2 / 3);
    max-width: percentage(2 / 3);
  }
  .col-lg-1-3 {
    flex-basis: percentage(1 / 3);
    width: percentage(1 / 3);
    max-width: percentage(1 / 3);
  }
  .col-lg-1-2 {
    flex-basis: percentage(1 / 2);
    width: percentage(1 / 2);
    max-width: percentage(1 / 2);
  }
  .col-lg-1-4 {
    flex-basis: percentage(1 / 4);
    width: percentage(1 / 4);
    max-width: percentage(1 / 4);
  }
  .col-lg-1-6 {
    flex-basis: percentage(1 / 6);
    width: percentage(1 / 6);
    max-width: percentage(1 / 6);
  }
  .col-lg-1-5 {
    flex-basis: percentage(1 / 5);
    width: percentage(1 / 5);
    max-width: percentage(1 / 5);
  }
  .col-lg-2-5 {
    flex-basis: percentage(2 / 5);
    width: percentage(2 / 5);
    max-width: percentage(2 / 5);
  }
  .col-lg-3-5 {
    flex-basis: percentage(3 / 5);
    width: percentage(3 / 5);
    max-width: percentage(3 / 5);
  }
}

@include xl {
  .col-xl-1-1 {
    flex-basis: percentage(1 / 1);
    width: percentage(1 / 1);
    max-width: percentage(1 / 1);
  }
  .col-xl-2-3 {
    flex-basis: percentage(2 / 3);
    width: percentage(2 / 3);
    max-width: percentage(2 / 3);
  }
  .col-xl-1-3 {
    flex-basis: percentage(1 / 3);
    width: percentage(1 / 3);
    max-width: percentage(1 / 3);
  }
  .col-xl-1-2 {
    flex-basis: percentage(1 / 2);
    width: percentage(1 / 2);
    max-width: percentage(1 / 2);
  }
  .col-xl-1-4 {
    flex-basis: percentage(1 / 4);
    width: percentage(1 / 4);
    max-width: percentage(1 / 4);
  }
  .col-xl-1-8 {
    flex-basis: percentage(1 / 8);
    width: percentage(1 / 8);
    max-width: percentage(1 / 8);
  }
  .col-xl-1-6 {
    flex-basis: percentage(1 / 6);
    width: percentage(1 / 6);
    max-width: percentage(1 / 6);
  }
}
