figure {
  width: 80%;
  max-width: 20rem;
  margin: 0 auto;

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
