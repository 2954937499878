@font-face {
  font-family: 'CircularWeb';
  src:  url('../fonts/CircularStd-Book.woff2') format('woff2'),
        url('../fonts/CircularStd-Book.woff') format('woff');
  font-weight: normal;
}
@font-face {
  font-family: 'CircularWeb';
  src:  url('../fonts/CircularStd-Bold.woff2') format('woff2'),
        url('../fonts/CircularStd-Bold.woff') format('woff');
  font-weight: bold;
}

/**
 * @license
 * MyFonts Webfont Build ID 3468241, 2017-10-20T10:46:11-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FreightTextProMedium-Italic by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-text-pro/medium-italic/
 *
 * Webfont: FreightTextProBook-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-text-pro/book/
 *
 * Webfont: FreightTextProMedium-Regular by GarageFonts
 * URL: https://www.myfonts.com/fonts/garagefonts/freight-text-pro/medium/
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3468241
 * Licensed pageviews: 100,000
 * Webfonts copyright: Copyright &#x00A9; 2004-2009, Joshua Darden &amp; Phil's Fonts, Inc. All rights reserved. Published by Garagefonts USA 301-879-9222 www.garagefonts.com
 *
 * © 2017 MyFonts Inc
*/

@import url("//hello.myfonts.net/count/34ebd1");

@font-face {
  font-family: 'FreightTextPro';//-Italic';
  src: url('../fonts/34EBD1_0_0.eot');
  src: url('../fonts/34EBD1_0_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/34EBD1_0_0.woff2') format('woff2'),
       url('../fonts/34EBD1_0_0.woff') format('woff'),
       url('../fonts/34EBD1_0_0.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'FreightTextPro';//-Regular';
  src: url('../fonts/34EBD1_1_0.eot');
  src: url('../fonts/34EBD1_1_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/34EBD1_1_0.woff2') format('woff2'),
       url('../fonts/34EBD1_1_0.woff') format('woff'),
       url('../fonts/34EBD1_1_0.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FreightTextPro';//-Regular';
  src: url('../fonts/34EBD1_2_0.eot');
  src: url('../fonts/34EBD1_2_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/34EBD1_2_0.woff2') format('woff2'),
       url('../fonts/34EBD1_2_0.woff') format('woff'),
       url('../fonts/34EBD1_2_0.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


h1 {

}

h2 {
  font-weight: bold;
  text-align: center;
  padding-top: .35em;
  padding-bottom: $base-padding;
  line-height: 1.1em;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: -0.02em;
  max-width: 40rem;
  font-size: 1.6rem;

  @include md {
    font-size: 2rem;
    padding-bottom: $base-padding*1.5;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  a {
    position: relative;
    display: inline-block;
    color: inherit;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: .035em;
      background: currentColor;
      transition: transform .2s ease;
      transform: translate3d(0,0,0) scaleX(1);
      transform-origin: center;
    }

    &:hover:after {
      transform: translate3d(0,0,0) scaleX(0);
    }
  }
}

h3 {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: .35em;
  line-height: 1.1em;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: -0.02em;

  @include sm {
    font-size: 1.4rem;
  }

  &.serif {
    font-family: $text-font-family;
  }

  @include md { font-size: 1.6rem }
  @include lg { font-size: 1.8rem }
}

h4 {

}

a {
  text-decoration: none;
  color: inherit;
}

p {
  font-size: 1rem;
  font-family: $text-font-family;
  line-height: 1.6em;
  margin-bottom: 1.6em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @include sm {
    font-size: .95rem;
  }

  @include lg {
    font-size: 1rem;
  }

  // &:last-child {
  //   margin-bottom: 0;
  // }

  a {
    position: relative;
    display: inline-block;
    color: $blue;

    &:after {
      content: '';
      position: absolute;
      bottom: .25em;
      left: 0;
      width: 100%;
      height: 1px;
      background: currentColor;
      transition: transform .2s ease;
      transform: translate3d(0,0,0) scaleX(1);
      transform-origin: center;
    }

    &:hover:after {
      transform: translate3d(0,0,0) scaleX(0);
    }
  }
}

em {
  font-style: italic;
}

blockquote {

  p {
    font-family: $base-font-family;
    font-size: 1rem;
    line-height: 2em;
    text-align: left;

    @include md {
      font-size: 1.1rem;
    }
  }
}

.text-align {
  &--center { text-align: center }
  &--right { text-align: right }
  &--left { text-align: left }
}
