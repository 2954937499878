.landscape {
  font-size: $base-font-size - 3;

  .content {
    &-nav {
      &-item {
        width: 32%;
        bottom: 0;
        top: auto;
        font-size: .85rem;
        height: $base-padding*1.25;
        line-height: $base-padding*1.25;
        letter-spacing: -.002;

        a {
          height: $base-padding*1.25;
          line-height: $base-padding*1.25;
        }

        &:nth-child(2) {
          left: 32%;
        }

        &:nth-child(3) {
          width: 36%;
          left: 64%;
        }

        &-close {
          width: $base-padding * 1.25;
        }

        &--open a span {
          transform: translate3d(($base-padding * -1.5)/2, 0, 0);
        }
      }
    }

    &-section {
      top: 100%;
      height: calc(100% - #{$base-padding * 1.25});
    }
  }

  .video {
    bottom: $base-padding;
  }

  .content-section-books {
    max-width: 24rem;
    margin: 0 auto;
  }

  .content-section-text-content {
    max-width: 30rem;
  }
}

.credits-text { display: none }
