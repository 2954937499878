*, *::before, *::after {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
  outline: 0;
}

::selection {
  background: $base-selection-color;
}

::-moz-selection {
  background: $base-selection-color;
}

html, body {
  background: $grey;
  font: $base-font-size - 3 $base-font-family;
  color: $base-font-color;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;

  @include sm { font-size: $base-font-size }
  @include md { font-size: $base-font-size }
  @include lg { font-size: $base-font-size + 2}
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
}
