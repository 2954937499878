.video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: $base-padding * 4.5;
  background: $grey;
  z-index: 1;

  @include sm {
    bottom: $base-padding * 5.25;
  }

  video {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100vw;
    transform: translateY(-50%);

    @supports (object-fit: cover) {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate3d(0,0,0);
    }
  }

  @include md {
    bottom: $base-padding * 1.5;
  }
  @include lg {
    bottom: $base-padding * 1.75;
  }

  &-mute {
    position: absolute !important;
    bottom: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    border-radius: 50%;
    transform: translate3d(0,0,0);
    overflow: visible !important;

    @include xs {
      width: 3.5rem;
      height: 3.5rem;
    }

    .waves-ripple {
      background-color: rgba(#fff, .075) !important;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.7rem;
      height: 1.7rem;
      transform: translate3d(-50%,-50%,0);
      background: url(../images/icon-mute.svg) top left / 100% auto no-repeat;
      opacity: .2;
      transition: opacity .2s ease;
    }

    &:hover:before {
      opacity: 1;
    }

    &--unmute:before {
      background-position: bottom left;
    }
  }
}

.credits {
  position: absolute;
  top: $base-padding/1.5;
  right: $base-padding/1.5;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .85rem;
  text-align: right;
  font-family: $text-font-family;
  font-style: italic;
  color: #fff;
  user-select: none;

  &-text {
    display: none;

    @include sm {
      display: block;

      &:after {
        display: none;
        content: '';
        position: absolute;
        bottom: .6em;
        left: 6.85em;
        right: 2.75em;
        height: 1px;
        background: currentColor;
        transition: transform .2s ease;
        transform: translate3d(0,0,0) scaleX(0);
        transform-origin: left;

        @include md {
          display: block;
        }
      }
    }
  }

  &-logo {
    position: relative;
    width: #{84rem * .01999};
    height: #{96rem * .02};
    display: block;
    margin-left: .75em;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../images/logo-white.svg) top left / auto 100% no-repeat;
      opacity: 1;
      transition: opacity .2s ease;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(../images/logo.svg) top left / auto 100% no-repeat;
      opacity: 0;
      transition: opacity .2s ease;
    }
  }

  &:hover &-text:after {
    transform: translate3d(0,0,0) scaleX(1);
  }

  &:hover &-logo:after {
    opacity: 1;
  }
  &:hover &-logo:before {
    opacity: 0;
  }

}
