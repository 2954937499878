.content-section-blocks {
    max-width: 50rem;

  @include sm {
    margin: 0 $base-padding * 1.1;
  }

  @include md {
    margin: 0 auto;
  }

  &-block {
    color: $blue;
    background: $green;
    margin-left: $base-padding/1.5;
    min-height: 10rem;
    display: flex;

    h3 {
      max-width: 9em;
      margin: 0
    }

    @include md {
      margin-left: $base-padding * 3;
      margin-right: $base-padding * 1.5;
    }

    &:nth-of-type(2n) {
      margin-left: 0;
      margin-right: $base-padding/1.5;

      @include md {
        margin-left: $base-padding * 1.5;
        margin-right: $base-padding * 3;
      }
    }

    &:first-child, &:last-child {
      margin-left: $base-padding/1.5;
      margin-right: $base-padding/1.5;

      @include md {
        margin-left: $base-padding * 3;
        margin-right: $base-padding * 3;
      }
    }

    &:last-child {
      margin-bottom: $base-padding/1.5;

      @include md {
        margin-bottom: $base-padding * 1.5;
      }
    }

    &-text {
      padding: $base-padding/2;
      width: 100%;
      min-height: 12rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      @include sm {
        padding: $base-padding/1.5;
      }

      @include md {
        min-height: 14rem;
      }

      @include lg { min-height: 16rem }

      &-link {
        font-size: .85rem;
        color: $beige;
        width: auto;
      }

      a.content-section-blocks-block-text-link {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 110%;
          left: 0;
          width: 100%;
          height: 1px;
          background: currentColor;
          transition: transform .2s ease;
          transform: translate3d(0,0,0) scaleX(1);
          transform-origin: center;
        }

        &:hover:after {
          transform: translate3d(0,0,0) scaleX(0);
        }

        &[data-content*="@"]:after {
          display: none;
        }
      }

      .hashtag {
        color: $blue;
      }
    }

    &-image {
      width: auto;
      height: auto;
      min-height: 12rem;
      max-width: 40%;
      overflow: hidden;

      @include md { min-height: 14rem }
      @include lg { min-height: 16rem }

      @at-root .content-section-locations-map + .content-section-blocks & {
        @include lg {
          min-height: 12rem;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }

    &--center {
      text-align: center;
      align-items: center;
      justify-content: center;
      align-items: flex-start;


      h3 {
        margin: auto;
        max-width: 14em;

        // @include xs { font-size: 1.5rem; }
      }

      .content-section-blocks-block-text-link {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &--center &-text {
      padding: $base-padding/2 $base-padding;

      &--small {
        @include xs {
          h3 {
            font-size: 1.25rem;
            line-height: 1.2em;
          }
        }
      }
    }

    // colors
    &:nth-of-type(4n+1) {
      background: $green;
    }
    &:nth-of-type(4n+2) {
      background: $blue;
      color: $green;

      .hashtag {
        color: $beige;
      }
    }
    &--orange {
      background: $orange;
      color: $beige;
    }
    &:nth-of-type(4n) {
      background: $beige;
    }
    &:nth-of-type(4n) &-text-link {
      color: $blue;
    }
    &:nth-of-type(4n+3) {
      background: $lightblue;
      color: $beige;
    }
  }
}
