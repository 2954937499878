.content-section-books {
  max-width: 58rem;
  margin: 0 auto;

  &-year {
    text-align: center;
    display: block;
    padding: $base-padding/2 $base-padding $base-padding;
    color: $orange;
  }

  .tns-slider {
    display: flex;
    align-items: flex-end;
  }

  .tns-outer {
    overflow: visible;

    button { display: none}
  }

  &-related {
    &-book {
      padding: 0 $base-padding/2 $base-padding;
      @include md {
        padding: 0 $base-padding $base-padding;
      }

      img {
        display: block;
        width: 100%;
        transform: translate3d(0,0,0) scale(1);
        transform-origin: bottom;
        transition: transform .15s ease;

        &:hover {
          transform: translate3d(0,0,0) scale(1.02);
        }

      }
    }
  }

  &-book {
    position: relative;
    color: $black;
    background: $beige;
    margin-left: $base-padding * 2;
    margin-right: $base-padding;
    margin-bottom: $base-padding;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media (max-width: $size-md) {
      margin-left: $base-padding * .75;
      margin-bottom: $base-padding * .75;
      margin-right: $base-padding * .75;
      flex-direction: column-reverse;
    }

    @media (min-width: $size-xs) and (max-width: $size-md) {
      margin-right: $base-padding * 1.75;
      margin-left: $base-padding * 1.75;
      margin-bottom: $base-padding * 1.75;
    }

    h3 {
      margin: 0;

      &:first-of-type {
        margin: 0 0 .25em;
      }
    }

    p {
      font-size: .9rem;
      margin: 1.5em auto 0;
      max-width: 24em;

      a {
        color: $black;
      }
    }

    &:nth-of-type(2n) {
      margin-left: 0;
      margin-right: $base-padding * 1.5;

      @media (min-width: $size-xs) and (max-width: $size-md) {
        margin-right: $base-padding * 2.625;
        margin-left: $base-padding * 0.625;
        margin-bottom: $base-padding * 1.75;
      }

      @include md {
        flex-direction: row-reverse;
        margin-left: $base-padding;
        margin-right: $base-padding * 2;
      }
    }


    &-text {
      padding: 0 $base-padding;
      width: 100%;

      @media (max-width: $size-md) {
        padding: $base-padding;
      }

      > * {
        flex-grow: 0;
        flex-shrink: 0;
      }

      // @include md {
      //   height: 14rem;
      // }
      // @include lg {
      //   height: 16rem;
      // }
    }

    &-current {
      position: absolute;
      top: 0;
      left: 0;
      width: 5rem;
      height: 5rem;
      background: url(../images/current.svg) center / 100% auto no-repeat;
      animation: rotate 15s linear infinite;
      pointer-events: none;
      z-index: 2;
      transform: translate3d(-50%,-50%,0) rotate(0deg);

      @include md {
        width: 6.5rem;
        height: 6.5rem;
      }
    }

    &:nth-of-type(2n) &-current {
      right: 0;
      left: auto;
      transform: translate3d(50%, -50%, 0) rotate(0deg);
      animation: rotate2 15s linear infinite;
    }

    @keyframes rotate {
      0% {
        transform: translate3d(-50%,-50%,0) rotate(0deg);
      }
      100% {
        transform: translate3d(-50%,-50%,0) rotate(-360deg);
      }
    }
    @keyframes rotate2 {
      0% {
        transform: translate3d(50%,-50%,0) rotate(0deg);
      }
      100% {
        transform: translate3d(50%,-50%,0) rotate(-360deg);
      }
    }

    &-image {
      display: flex;
      align-items: stretch;
      position: relative;
      width: 100%;
      height: auto;
      margin-right: -1px;
      @include clearfix;

      @include md {
        display: flex;
        flex-shrink: 0;
        width: auto;
        height: 19.5rem; }

      @include lg { height: 22rem; }

      &-credits {
        position: absolute;
        bottom: .8em;
        left: 1em;
        right: 50%;
        padding-right: 1rem;
        color: $beige;
        text-align: left;
        font-size: .7em;

        span {
          display: inline-block;
        }
      }

      &-img {
        width: 50%;
        height: auto;
        flex-shrink: 0;
        flex-grow: 0;
        overflow: hidden;

        @include md {
          width: 19.5rem * 0.589;
          height: 19.5rem;
        }
        @include lg {
          width: 22rem * 0.589;
          height: 22rem;
        }
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        // height: calc((50vw - #{$base-padding/4}) * 1.695);
        object-fit: cover;
        font-family: 'object-fit: cover;';
      }
    }
  }
}
