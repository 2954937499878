.content-section-newsletter {
  position: relative;
  background: $grey;
  margin-left: $base-padding/1.5;
  margin-bottom: $base-padding/1.5;
  min-height: 10rem;
  padding: $base-padding/2;
  color: #fff;
  z-index: 4;

  &-wrapper {
    margin: 0 auto;
    max-width: 50rem;
  }

  @include md {
    padding: $base-padding;
    margin: $base-padding*-1 $base-padding * 3 $base-padding $base-padding * 1.5;
    display: flex;
  }

  &-title {
    padding-right: $base-padding;
    flex-basis: 50%;

    @include smmax {
      padding: $base-padding/3;
    }
  }

  &-inputs {
    position: relative;
    flex-basis: 50%;
    padding-top: $base-padding/2;

    @include md {
      padding-top: $base-padding/3;
    }
  }

  h2 {
    margin: 0;
    padding-bottom: .35em;
    text-align: left;
    font-size: 1.9rem;

    @include md { font-size: 2.35rem }
  }

  p {
    font-family: $base-font-family;
    font-size: .85rem;
    line-height: 1.6em;
    text-align: left;
    font-weight: normal;
    margin: 0;
  }

}
