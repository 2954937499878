// colors
$beige: #F9F6DB;
$orange: #F86807;
$green: #93C13D;
$blue: #00619D;
$lightblue: #4d8baa;
$grey: #5D5D5D;
$brown: #898078;
$black: #231F1F;
$white: #fff;

// base
$base-font-size: 20px;
$base-selection-color: $green;
$base-font-color: $black;
$base-padding: 2rem;
$base-font-family: 'CircularWeb', Arial, sans-serif;
$text-font-family: 'FreightTextPro', serif;

$speed: .4s;
$ease: ease;

// sizes
$size-xs: 568px;
$size-md: 800px;
$size-lg: 1200px;
$size-xl: 1600px;
