.content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.content-nav {
  &-item {
    list-style: none;
    position: absolute;
    top: calc(100% - #{$base-padding * 4.5});
    left: 0;
    right: 0;
    z-index: 22;
    transform: translate3d(0,0,0);
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    height: $base-padding*1.5;

    @include sm {
      top: calc(100% - #{$base-padding * 5.25});
      height: $base-padding*1.75;
    }

    &-link {
      display: block;
      background: $orange;
      color: $beige;
      font-weight: bold;
      text-align: center;
      height: $base-padding * 1.5;
      line-height: $base-padding * 1.5;
      cursor: pointer;
      width: 100%;
      overflow: hidden;

      span {
        display: inline-block;
        transform: translate3d(0,0,0);
        transition: transform $speed/1.5 $ease;
      }

      @include md {
        font-size: .9rem;
      }

      @include sm {
        // font-size: 1.1rem;
        height: $base-padding * 1.75;
        line-height: $base-padding * 1.75;
      }
    }

    &:nth-child(2) {
      @include smmax {
        top: calc(100% - #{$base-padding * 3.5});
      }
      @include xs {
        top: calc(100% - #{$base-padding * 3});
      }

      .content-nav-item-link { background: $grey }
    }
    &:nth-child(3) {
      @include smmax {
        top: calc(100% - #{$base-padding * 1.75});
      }
      @include xs {
        top: calc(100% - #{$base-padding * 1.5});
      }

      .content-nav-item-link { background: $brown }
    }

    @include md {
      top: auto;
      bottom: 0;
      width: 33.333%;

      &:nth-child(2) {
        left: 33.333%;
        top: auto;
        bottom: 0;
      }
      &:nth-child(3) {
        left: 66.666%;
        top: auto;
        bottom: 0;
      }
    }

    &-close {
      position: absolute;
      top: 0;
      right: 0;
      width: $base-padding * 1.5;
      bottom: 0;
      background: #fff;
      transform: translate3d(100%,0,0);
      transition: transform $speed/1.5 $ease;
      overflow: hidden;

      @include sm {
        width: $base-padding * 1.75;
      }

      i {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: transform $speed/1.5 $ease;
        transform: translate3d(-100%,0,0);

        &:before, &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          height: 2px;
          width: 40%;
          background: $black;
        }

        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    &-link--active + &-close {
      transition-delay: $speed/1.25;
      transform: translate3d(0,0,0);

      i {
        transition-delay: $speed/1.25;
        transform: translate3d(0,0,0);
      }
    }

    &-link--active span {
      transition-delay: $speed/1.25;
      transform: translate3d(#{$base-padding * -.75},0,0);

      @include sm {
        transform: translate3d(0,0,0);
      }

      @include md {
        transform: translate3d(#{($base-padding * -1.75) / 2},0,0);
      }
    }
  }
}

.content-section {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 11;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: $white;
  transform: translate3d(0,0,0);
  scroll-behavior: smooth;

  @include smmax {
    top: calc(100% - #{$base-padding * 3.5});
    height: calc(100% - #{$base-padding * 5.25});

    &:nth-child(3) { top: calc(100% - #{$base-padding * 1.75}) }
    &:nth-child(4) { top: 100% }
  }
  @include xs {
    top: calc(100% - #{$base-padding * 3});
    height: calc(100% - #{$base-padding * 4.5});

    &:nth-child(3) { top: calc(100% - #{$base-padding * 1.5}) }
    &:nth-child(4) { top: 100% }
  }

  @include md {
    top: 100%;
    height: calc(100% - #{$base-padding * 1.75});
  }

  &--open {
    transform: translateY(0,-100%,0);
    transform: translate3d(0,-100%,0);
  }

  &-text {
    height: 100%;

    &-content {
      padding: $base-padding*1.5 $base-padding*.75;

      @include sm {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: $base-padding * 1.5;
      }

      @include md {
        width: 80%;
        padding: $base-padding*2 $base-padding * 1.5;

        &:last-child {
          min-height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      h2 {
        font-size: 1.9rem;
        padding-bottom: .8em;

        @include md { font-size: 2.35rem }
      }

      @include lg {
        width: 75%;
        max-width: 45rem;
      }
    }
  }
}
