.form {
  position: relative;

  &-input {
    font-family: inherit;
    font-size: inherit;
    width: 100%;
    border: 0 solid #BFBFBF;
    margin-bottom: $base-padding / 3;
    padding: 0 1em;
    height: $base-padding * 1.5;
    line-height: $base-padding * 1.4;
    appearance: none;
    color: inherit;
    background: #fff;
    border-radius: 0;

    &-hidden {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    &[type="text"], &[type="email"] {
      box-shadow: inset 0 4rem #fff;

      &[disabled] {
        color: $brown !important;
      }
    }

    @include sm {
      // border-width: 2px;
      height: $base-padding * 1.4;
      line-height: $base-padding * 1.3;
    }

    @include md {
      // height: $base-padding * 1.2;
      // line-height: $base-padding * 1;
      font-size: .9rem;
    }

    @include placeholder {
      color: darken(#BFBFBF, 14%);
    }
  }

  &-submit {
    background: $orange;
    border-color: $orange;
    color: $beige;
    font-weight: bold;
    width: 100%;
  }

  .message {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    font-family: $base-font-family;
    color: $beige;
    background: $orange;
    height: $base-padding*2;
    line-height: $base-padding * 1.4;

    &:empty {
      display: none;
    }
  }
}
