.content-section-locations {
  @include md {
    display: flex;
    width: 100%;
    margin-top: -$base-padding;
  }

  &-map {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    @include smmax {
      margin-top: -$base-padding;
    }

    @include md {
      display: flex;
      height: calc(85vh - #{$base-padding*2.25});
      top: 0;
      margin: -$base-padding $base-padding*1.75 0;
      width: auto;
    }

    &-map {
      background: $grey;
      width: 100%;

      @include smmax {
        margin-top: $base-padding*-4.15;
        height: calc(112.5vw + #{$base-padding*4.15});
      }

      @include md {
        margin-left: -18rem;
        width: calc(100% + 18rem);
      }

      .mapboxgl-ctrl-logo { opacity: .2 !important}
      .mapboxgl-ctrl-attrib { opacity: .2 !important}

      &-zoom {
        box-shadow: none !important;

        @include md {
          left: 18.5rem !important;
        }

        button {
          border: 0;
          background-color: #fff !important;
          opacity: 1 !important;
        }
      }

      &-marker {
        position: relative;
        width: 1.5rem;
        height: 2rem;
        margin: -2rem -.75rem 0;
        cursor: pointer;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: auto 100%;
          background-position: center;
          background-repeat: no-repeat;
          transform-origin: bottom;
          transition: transform .25s ease;
        }

        &--abri:before { background-image: url(../images/marker-blue.svg)}
        &--spread:before { background-image: url(../images/marker-green.svg)}
        &--case:before { background-image: url(../images/marker-orange.svg)}

        &:hover:before {
          transform: translate3d(0,0,0) scale(1.15);
        }
      }
    }

    &-list {
      position: absolute;
      left: $base-padding/2;
      right: $base-padding/2;
      bottom: $base-padding/2;
      z-index: 3;
      overflow: auto;

      @include md {
        width: 18rem;
        left: auto;
        top: $base-padding/2;
        bottom: auto;
        max-height: calc(100% - #{$base-padding});
      }

      @include smmax {
        // max-height: $base-padding*4.15;
        position: relative;
        left: 0;
        right: 0;
        margin-top: -3rem;
      }

      &-hidden {
        margin-top: $base-padding/4;
        display: flex;
        flex-direction: column;

        li > div {
          opacity: .4;
        }

        // &:before {
        //   content: '';
        //   display: block;
        //   height: 1px;
        //   margin: $base-padding/2 $base-padding/2 0;
        //   background: #f2f2f2;
        // }
      }

      &-container {
        height: 100%;
        overflow: auto;
        font-size: .8rem;
        display: flex;
        flex-direction: column;

        @include smmax {
          overflow: hidden;
        }
      }
    }
  }

  &-location {
    position: relative;
    padding: $base-padding/2;
    display: flex;
    cursor: pointer;
    background: #fff;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      left: $base-padding/2;
      right: $base-padding/2;
      background: #f2f2f2;
    }

    &:last-of-type:after {
      display: none;
    }

    &--abri { color: $blue }
    &--spread { color: $green; order: -1; }
    &--case { color: $orange }

    &-wrapper {
      width: 100%;
    }

    &-title {
      width: 100%;
    }

    &-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: $grey;
    }

    &-marker {
      width: 1.5rem;
      height: 2rem;
      background-position: left;
      background-repeat: no-repeat;
      background-size: auto 100%;
      margin-right: $base-padding/3;
      margin-left: -.45em;
      flex-shrink: 0;
    }
    &--abri &-marker { background-image: url(../images/marker-blue.svg)}
    &--spread &-marker { background-image: url(../images/marker-green.svg)}
    &--case &-marker { background-image: url(../images/marker-orange.svg)}

    &-address {
      width: 100%;

      &:first-letter {
        text-transform: capitalize;
      }
    }

    &-options {
      flex-shrink: 0;
      margin-left: $base-padding/4;
      color: $grey;

      a {
        position: relative;
        display: inline-block;

        &:after {
          content: '';
          position: absolute;
          bottom: 0em;
          left: 0;
          width: 100%;
          height: 1px;
          background: currentColor;
          transition: transform .2s ease;
          transform: translate3d(0,0,0) scaleX(1);
          transform-origin: center;
        }

        &:hover:after {
          transform: translate3d(0,0,0) scaleX(0);
        }
      }
    }


  }

  .content-section-blocks {
    width: 100%;
    padding: $base-padding/2 0;

    @include md {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 0 $base-padding;
    }
  }
}
